export { create } from './methods/create.js'
export { count, getByID } from './methods/docs.js'
export { insert, insertMultiple } from './methods/insert.js'
export { remove, removeMultiple } from './methods/remove.js'
export { search } from './methods/search.js'
export { searchVector } from './methods/search-vector.js'
export { load, save } from './methods/serialization.js'
export { update, updateMultiple } from './methods/update.js'

export * from './types.js'
export * as components from './components.js'
export * as internals from './internals.js'

export type { RawData } from './methods/serialization.js'
export type { Language } from './components/tokenizer/languages.js'
